<template>
  <div>
    <b-form>
      <b-form-checkbox
          v-model="cgvAccepted"
          :id="idCheckBox"
          name="acceptCGVCheckboxName"
          v-on:change="onClickAccept(cgvAccepted)"
      >
        I agree to the <b-link v-b-modal.modal-cgv>terms and conditions</b-link>
      </b-form-checkbox>
    </b-form>
    <p v-if="showCGVAlert" class="text-danger"> <b-icon-arrow-up></b-icon-arrow-up> To Continue you have to agree to the terms and conditions</p>
    <b-modal id="modal-cgv" scrollable title="Terms and Conditions">
      <CGV></CGV>
    </b-modal>
  </div>
</template>

<script>
import { BIconArrowUp } from 'bootstrap-vue'
import CGV from "@/components/CGV";

export default {
  name: "AcceptCGV",
  components: {CGV,BIconArrowUp},
  props: {
    showCGVAlert:Boolean,
    idCheckBox: String
  },
  data() {
    return {
      cgvAccepted: false,
    }
  },
  methods: {
    onClickAccept(){
      this.$emit('cgv-accepted', this.cgvAccepted)
    },

  }
}
</script>

<style scoped>

</style>