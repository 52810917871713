<template>
  <div>
    <NavBar></NavBar>
    <div class="container">
      <h5 class="mt-5 mb-5"><i class="bi bi-bag"></i> Shopping Cart</h5>
      <Loader v-if="loading"></Loader>
      <div v-else>

        <div class="text-center mb-5 mt-5" v-if="emptyCart">
          <p>Your cart is currently empty</p>
          <p>Take a look at our products : </p>
          <b-button :to="{ name: routeNames.PRODUCTS}" variant="info">Products</b-button>
          <br>
          <b-button class="mt-3" :to="{ name: routeNames.TUTORIAL,params : {tutorialId: this.tutorialId, slug: 'build-ecommerce-website-golang-vuejs-gin-aws-lambda'}}" variant="info">Tutorial</b-button>
        </div>
        <div v-else>
          <!-- Cart is not empty -->
          <b-container class="m-2 pb-1">
            <b-alert variant="success" dismissible show>We'll hold products in your cart for <strong>an hour</strong></b-alert>
            <b-row>
              <b-col class="mt-2">
                Subtotal: {{cart.totalPriceVATInc.display}}
              </b-col>
              <b-col>
                <b-button block variant="success" v-b-modal.modal-1 @click="onClickPayButton">Pay</b-button>
              </b-col>
            </b-row>
            <hr>
            <!--- ITEMS -->
            <CartView :items="cart.items" v-on:cart-updated="fetchData" :updatable="true"></CartView>
          </b-container>
          <!--- END ITEMS -->
          <!--- Basket Footer -->
          <b-container>
            <b-row >
              <b-col class="text-center">
                Only original items
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center m-4">
                Free return
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col>
                Subtotal:
              </b-col>
              <b-col class="text-right">
                {{cart.totalPriceVATInc.display}}
              </b-col>
            </b-row>
            <hr>
            <b-row class="mt-2">
              <b-col>
                Shipping:
              </b-col>
              <b-col class="text-right">
                <p>Shipping calculated at checkout</p>
              </b-col>
            </b-row>
            <b-button class="mt-2" block variant="success" v-b-modal.modal-1 @click="onClickPayButton" >Pay</b-button>
            <div class="mt-2">
              <p class="text-center">Secured payment provided by Stripe and Paypal</p>
            </div>
            <b-row class="text-center mt-2 mb-2">
              <b-row>
                <b-col>
                  <WeAcceptPaypal></WeAcceptPaypal>
                </b-col>
                <b-col class="text-center my-auto ">
                  <PoweredByStripe width="200px"></PoweredByStripe>
                </b-col>
              </b-row>
            </b-row>
            <b-row>
              <b-col class="text-center mb-2">
                <h5>Do you need help ?</h5>
                <p>A doubt ? Do you want to speak to Maximilien ?</p>
                <b-button variant="info" class="mb-2" @click="openCrisp">
                  <i class="bi bi-chat"></i>
                  Open the chat
                </b-button>
              </b-col>
            </b-row>
          </b-container>
          <!--- End  Basket Footer -->
        </div>
        <div class="alert alert-danger mt-3" role="alert" v-if="showErrorAlert">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <b-modal id="modal-1" :title="`Payment Method`" hide-footer>
      <CountrySelector v-on:input="countrySelected" v-if="this.cart && this.cart.isAddressNeeded"></CountrySelector>
      <AcceptCGV :show-c-g-v-alert="showCGVAlert" v-on:cgv-accepted="onCgvAccepted"
                 id-check-box="check-CGV"></AcceptCGV>
      <br>
      <b-button block variant="success" :disabled="disablePayButton" @click="onClickPayStripe">
        <b-spinner v-if="showSpinnerStripe" small></b-spinner>
        Pay With Card (Stripe)
      </b-button>
      <b-button block :disabled="disablePayButton" variant="info" @click="onClickPayPaypal">Pay With Paypal
      </b-button>
    </b-modal>
    <Footer></Footer>
  </div>
</template>

<script>
const errMessageExpire = "Your cart is expired (items reserved for 1 hour max), please add products again. Sorry for the inconvenience."

import NavBar from "../components/NavBar";
import AuthHelper from "../authHelper";
import Webservice from "../webservice";
import Loader from "../components/Loader";
import Consts from "@/consts";
import WeAcceptPaypal from "@/components/WeAcceptPaypal";
import PoweredByStripe from "@/components/PoweredByStripe";
import CartView from "@/views/CartView";
import ScriptLoader from "@/scriptLoader";
import CountrySelector from "@/components/CountrySelector";
import Footer from "@/components/Footer";
import AcceptCGV from "@/components/AcceptCGV";
export default {
  name: "Cart",
  components: {AcceptCGV, Footer, CountrySelector, CartView, PoweredByStripe, WeAcceptPaypal, Loader, NavBar},
  data(){
    return {
      cgvAccepted:false,
      showCGVAlert: false,
      shippingCountryCode: "",
      disablePayButton: false,
      showSpinnerStripe: false,
      errorMessage: "",
      showErrorAlert : false,
      loading: false,
      cart: null,
      emptyCart: false,
      routeNames : Consts.ROUTE_NAMES,
      currency:"EUR",
      tutorialId: process.env.VUE_APP_MAIN_TUTORIAL_ID,
    }
  },
  created() {
    this.loading = true
    this.fetchData()

  },
  methods: {
    onClickPayButton(){
      AuthHelper.getOrCreateAnonUser().then((user) => {
        Webservice.tracking({
          action:{
            referrer:document.referrer,
            lang: navigator.language || navigator.userLanguage,
            name:"CLICK_PAY_BUTTON",
            utm: {
              source: this.$route.query.utm_source,
              medium: this.$route.query.utm_medium,
              campaign: this.$route.query.utm_campaign,
              term: this.$route.query.utm_term,
              content: this.$route.query.utm_content,
            }
          }
        }, user.idToken)
      })


    },
    onCgvAccepted(accepted) {
      this.cgvAccepted = accepted
    },
    countrySelected(sel) {
      if (sel !== ""){
        this.disablePayButton = false
        this.shippingCountryCode = sel
      }
    },
    openCrisp() {
      window.$crisp.push(['do', 'chat:open'])
    },
    async onClickPayStripe(){
      if (this.cart){
        this.isPayable().then(()=>{
          this.payWithStripe()
        }).catch((err)=>{
          console.error(err)
          this.makeToast("Problem",errMessageExpire, "danger")
        })
      }
    },
    onClickPayPaypal(){
      if (!this.cgvAccepted) {
        this.showCGVAlert = true
        return
      }
      if (this.cart){
        this.isPayable().then(()=>{
          AuthHelper.getOrCreateAnonUser().then((user)=> {
            Webservice.tracking({
              action: {
                referrer: document.referrer,
                lang: navigator.language || navigator.userLanguage,
                name: "CLICK_PAY_PAYPAL",
                utm: {
                  source: this.$route.query.utm_source,
                  medium: this.$route.query.utm_medium,
                  campaign: this.$route.query.utm_campaign,
                  term: this.$route.query.utm_term,
                  content: this.$route.query.utm_content,
                }
              }
            }, user.idToken)
          })
          this.$router.push({
            name:Consts.ROUTE_NAMES.CHECKOUT_PAY,
            query:{countryCode:this.shippingCountryCode,currency:this.currency}
          })
        }).catch((err)=>{
          console.error(err)
          this.makeToast("Problem",errMessageExpire, "danger")
        })
      }
    },
    payWithStripe(){
      if (!this.cgvAccepted) {
        this.showCGVAlert = true
        return
      }
      this.disablePayButton = true
      this.showSpinnerStripe = true
      AuthHelper.getOrCreateAnonUser().then((user) => {
          Webservice.tracking({
            action:{
              referrer:document.referrer,
              lang: navigator.language || navigator.userLanguage,
              name:"CLICK_PAY_STRIPE",
              utm: {
                source: this.$route.query.utm_source,
                medium: this.$route.query.utm_medium,
                campaign: this.$route.query.utm_campaign,
                term: this.$route.query.utm_term,
                content: this.$route.query.utm_content,
              }
            }
          }, user.idToken)
        ScriptLoader.loadStripe().then(() => {
          let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
          Webservice.checkoutv2(user.idToken,"STRIPE",this.shippingCountryCode,"EUR").then((res) => {
            stripe.redirectToCheckout({sessionId: res.data.sessionId});
          }).catch((err) => {
            this.showSpinnerStripe = false
            this.disablePayButton = false
            console.error(err)
            this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
          })
        }).catch((err) => {
          this.showSpinnerStripe = false
          this.disablePayButton = false
          console.error(err)
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        });
      }).catch((err)=>{
        this.showSpinnerStripe = false
        this.disablePayButton = false
        console.error(err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")

      })
    },
    isPayable(){
      return new Promise( (resolve,reject) =>{
        AuthHelper.getOrCreateAnonUser().then((user)=> {
          Webservice.getCart(user.idToken).then((res)=>{
            this.cart = res.data
            if (res.data.items === null || res.data.items.length === 0){
              this.emptyCart = true
              reject("cart is empty")
            }
            resolve()
          }).catch((err)=>{
            if (err.response && err.response.status === 404){
              this.emptyCart = true
              reject("cart is empty")
            } else {
              console.error(err)
              console.log(err.response.status)
              reject("cart impossible to load")
            }
          })
        }).catch(()=>{
          // What to do ?
          // no user, display empty cart
          reject("cart is empty")
        })
      })
    },
    fetchData() {
      AuthHelper.getOrCreateAnonUser().then((user)=> {
        Webservice.getCart(user.idToken).then((res)=>{
          this.cart = res.data
          if (res.data.items === null || res.data.items.length === 0){
            this.emptyCart = true
          }
          this.loading = false
          Webservice.tracking({
            action:{
              referrer:document.referrer,
              lang: navigator.language || navigator.userLanguage,
              name:"VISIT_CART_PAGE",
              utm: {
                source: this.$route.query.utm_source,
                medium: this.$route.query.utm_medium,
                campaign: this.$route.query.utm_campaign,
                term: this.$route.query.utm_term,
                content: this.$route.query.utm_content,
              }
            }
          }, user.idToken)
        }).catch((err)=>{
          this.loading = false
          if (err.response && err.response.status === 404){
            this.emptyCart = true
          } else if (err.response && err.response.status === 403) {
            // create a new user
            AuthHelper.createAnonUser().then(()=> {
              // relaunch process
              this.fetchData()
            })
          } else {
            console.error("impossible to load cart : "+err)
            this.errorMessage = "Impossible to retrieve your cart, please retry later"
            this.showErrorAlert = true
          }
        })
      }).catch(()=>{
        this.loading = false
        // What to do ?
        // no user, display empty cart
        this.emptyCart = true
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  },

}
</script>

<style scoped>

</style>